<template>
  <div>
    <LazyMarketingNavigationHeader
      v-if="
        !route.path.includes('corporate') &&
        !route.path.includes('ace') &&
        !route.path.includes('the-breather')
      "
    />
    <LazyMinisitesCorporatesNavigationHeader
      v-if="
        route.path.includes('corporate') || route.path.includes('the-breather')
      "
    />
    <LazyMinisitesAceNavigationHeader v-if="route.path.includes('ace')" />
    <main
      :class="[
        'mt-[6.375rem]',
        route.path.includes('corporate') ||
        route.path.includes('ace') ||
        route.path.includes('the-breather')
          ? ''
          : 'lg:mt-[9.4375rem]',
      ]"
    >
      <slot />
    </main>
    <LazyMarketingNavigationFooter />
    <LazyMarketingNavigationScrollToTop />
    <LazyMarketingNavigationCookieBanner />
    <ClientOnly>
      <SpeedInsights />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { SpeedInsights } from '@vercel/speed-insights/nuxt';
const route = useRoute();
</script>
